import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cards from "./Cards";
import { cards } from "../static/ProjectData";
import "./PaginatedItem.css";
import "./Projects.css";

const Projects = ({ data, chooseRef }) => {
  return (
    <div className="popular-categories" ref={chooseRef} id="projects">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titlepage">
              <h2>Other Modules & Initiatives</h2>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center align-content-stretch gap-5">
          {data.map((proj, ind) => {
            return (
              <div
                className="text-center"
                key={ind}
                style={{
                  width: "25em",
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
              >
                <div className="slider-content">
                  <Link
                    to={"/product/" + encodeURI(data[ind].name)}
                    className="nav-links"
                  >
                    <img
                      className="image-shop-scroll"
                      src={proj.bgSiteImg}
                    ></img>
                  </Link>
                </div>
                <Link
                  to={"/product/" + encodeURI(data[ind].name)}
                  className="nav-links project-title justify-content-center"
                  style={{ height: "10%" }}
                >
                  {proj.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Projects;
