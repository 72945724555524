import React, { useEffect, useState } from "react";
import { Button } from "./Button";
import "./Featured.css";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { computeHeadingLevel } from "@testing-library/react";

const Featured = ({ data }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const modal = document.getElementById("myModal");
    const imgElements = document.getElementsByClassName("zoom");
    const modalImg = document.getElementById("img01");

    for (let i = 0; i < imgElements.length; i++) {
      imgElements[i].onclick = function () {
        modal.style.display = "block";
        modalImg.src = this.src;
      };
    }

    const span = document.getElementsByClassName("close")[0];

    span.onclick = function () {
      modal.style.display = "none";
    };
  }, []);

  // const data = [
  //   {
  //     id: 0,
  //     image: "images/1_THIRD_I.jpg",
  //     heading: "THIRD I PLATFORM",
  //     content: `Third I started in Nashik City in 2017 to provide smart
  //     & innovative Digital solutions for &#39;Real-Time
  //     Detection & Prevention of Crimes&#39;. After 14 months
  //     long research and working with Police Officials, IOs,
  //     Control Room Operators, field Constables and citizens,
  //     while keenly observing respective work & practices. We
  //     identified extensive scope for Tech interventions. Based
  //     on which, the Third I Platform was created, and many
  //     advanced versions of it were subsequently developed,
  //     tested & deployed in 15+ cities and districts with more
  //     than 55000 Police personnel as its current users.`,
  //     url: "https://demo.citypolice.in/",
  //   },
  //   {
  //     id: 1,
  //     image: "images/17_THIRD I vigilance.jpg",
  //     heading: "THIRD I V2",
  //     content: `This Version of Third I was developed to cater the
  //     requirement of Police with regards to prevent the crimes
  //     before happening and take all precautionary actions to
  //     maintain law & Order. It is conceptualized for State
  //     Intelligence Department & Special Branch of police to
  //     enhance surveillance & monitoring by giving it a digital
  //     edge. The desired solution will capture and help
  //     maintaining surveillance records digitally while
  //     conducting regular duties. It uses latest and improved
  //     tech stacks than old version of THIRD I.`,
  //     url: "https://v2.thirdi.app/",
  //   },
  //   {
  //     id: 2,
  //     image: "images/2_THIRD_I.png",
  //     heading: "THIRD I V3",
  //     content: `The Version 3 of Third I has been developed with latest
  //     modern technologies to help Police to get deep into the
  //     Data and generate advanced Data Anaytics, Machine Learning
  //     and AI based Recommendations. The aim of this endeavour
  //     was to embed an ML based Patrolling System which will
  //     guide police to take the optimal route, deploy resource
  //     and take action based on the Real-Time Crime Data. We
  //     studies many relevant research paper, while also
  //     evaluating other systems used in other countries.`,
  //     url: "https://delhi.citypolice.in",
  //   },
  //   {
  //     id: 3,
  //     image: "images/Third I CCTNS.png",
  //     heading: "THIRD I STATE",
  //     content: `This Project is built to provide a state level live
  //     Dashboard to visualize the crimes in the entire state by
  //     the relevant state autority meanwhile it also provide a
  //     centralized Data Platform for the state on a signel click.
  //     We developed it with making Data sharing partnership with
  //     WIPRO&#39;s CCTNS (Crime & Criminal Tracking Network &
  //     System) where they provided APIs for relevant State Crime
  //     Data`,
  //     url: "https://state.thirdi.app/",
  //   },
  // ];

  return (
    <div id="featured" style={{ backgroundColor: "white" }}>
      <div className="featured">
        <div className="container">
          <div
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="col-sm-12 text-center">
                  <h6>Featured Projects</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="row d_flex">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center">
                  <div
                    className="column video"
                    data-aos="slide-right"
                    data-aos-easing="ease"
                    data-aos-duration="800"
                  >
                    <div class="container">
                      <div class="laptop">
                        <div class="laptop__screen">
                          <img
                            src={data[index].image}
                            width="1600"
                            height="1000"
                            alt="Screen"
                          />
                        </div>
                        <div class="laptop__bottom">
                          <div class="laptop__under"></div>
                        </div>
                        <div class="laptop__shadow"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div
                    className="column video"
                    data-aos="slide-left"
                    data-aos-easing="ease"
                    data-aos-duration="800"
                  >
                    <div className="our_text_box">
                      <h3 className="awesome pa_wi">{data[index].name}</h3>
                      <p>{data[index].description}</p>

                      <div className="btn-access">
                        <div
                          style={{
                            backgroundColor: "black",
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <Link to={`/featured/${encodeURI(data[index].name)}`}>
                            <Button
                              className="btns"
                              buttonStyle="btn--outline"
                              buttonSize="btn--large"
                            >
                              Explore More
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-container">
              <div className="slider">
                <div
                  className="prev_icon"
                  style={{ width: "30px" }}
                  onClick={() =>
                    setIndex((prev) => (prev - 1 + data.length) % data.length)
                  }
                >
                  <img src="images/icons/previous.png" />
                </div>
                {[...Array(data.length)].map((_, i) => {
                  const isActive = i === index;
                  return isActive ? (
                    <div
                      key={i}
                      onClick={() => setIndex(i)}
                      className="period_icon"
                      style={{ width: "15px", marginRight: "10px" }}
                    >
                      <img src="images/icons/new-moon.png" alt="Full Moon" />
                    </div>
                  ) : (
                    <div
                      key={i}
                      onClick={() => setIndex(i)}
                      className="period_icon"
                      style={{ width: "15px", marginRight: "10px" }}
                    >
                      <img src="images/icons/dry-clean.png" alt="New Moon" />
                    </div>
                  );
                })}

                <div
                  className="next_icon"
                  style={{ width: "30px" }}
                  onClick={() => setIndex((prev) => (prev + 1) % data.length)}
                >
                  <img src="images/icons/next.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal" className="modal">
          <span className="close">&times;</span>

          <img className="modal-content" id="img01" />
        </div>
      </div>
    </div>
  );
};

export default Featured;
