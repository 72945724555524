import React from "react";

const CssClassMap = {
  0: "one",
  1: "two",
  2: "three",
  3: "four",
  4: "five",
  5: "six",
  6: "seven",
  7: "eight",
  8: "nine",
  9: "ten",
};

const Choose = ({ curData }) => {
  return (
    <>
      <div
        data-aos="flip-left"
        data-aos-delay="100"
        data-aos-anchor=".example-selector"
      >
        <div className="features_section" id="features_section">
          <div className="feat bg-gray pt-5 pb-5">
            <div className="container">
              <div
                className="row"
                style={{
                  alignItems: "stretch",
                  justifyContent: "center",
                }}
              >
                <div className="section-head col-sm-12">
                  <h4>
                    <span>Why Choose</span> Us?
                  </h4>
                  {/* <p>
                    When you choose us, you'll feel the benefit of 10 years'
                    experience of Web Development. Because we know the digital
                    world and we know how to handle it. With working knowledge
                    of online, SEO, and social media.
                  </p> */}
                </div>
                {curData?.features?.map((cur, index) => (
                  <div className="col-lg-4 col-sm-6 my-3" key={index}>
                    <div
                      data-aos="fade-zoom-in"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                      style={{
                        height: "100%",
                      }}
                    >
                      <div
                        className="item"
                        style={{
                          height: "100%",
                        }}
                      >
                        {" "}
                        <span
                          className={`icon feature_box_col_${CssClassMap[index]}`}
                        >
                          <img
                            src={cur.icon}
                            alt=""
                            style={{
                              width: "65%",
                              verticalAlign: "text-bottom",
                            }}
                          />
                        </span>
                        <h6>{cur.heading}</h6>
                        <p>{cur.para}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Choose;
