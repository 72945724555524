import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./CounterComponent.css";

const CounterComponent = () => {
  const [CounterOn, setCounterOn] = useState(false);
  const [counters, setCounters] = useState([
    {
      icon: "fa-solid fa-globe fa-2x",
      text: "Countries",
      target: 4,
      showSign: false,
    },
    {
      icon: "fa-solid fa-user fa-2x",
      text: "Clients",
      target: 30,
      showSign: true,
    },
    {
      icon: "fa-solid fa-users fa-2x",
      text: "Live users",
      target: 85000,
      showSign: true,
    },
    {
      icon: "fa fa-code fa-2x",
      text: "Live projects",
      target: 50,
      showSign: true,
    },
  ]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div data-aos="fade-up" data-aos-anchor-placement="top-center">
        <div className="container">
          <div className="row">
            <br />
            <div className="col-sm-12 text-center">
              <h6>Our Reach</h6>
            </div>
          </div>
          <ScrollTrigger
            onEnter={() => {
              setCounterOn(true);
            }}
            onExit={() => {
              setCounterOn(false);
            }}
            className="location"
          >
            <div className="row text-center numbers">
              {counters.map((counter, index) => (
                <div className="col" key={index}>
                  <div className="counter">
                    <i className={counter.icon}></i>

                    <h2 className="timer count-title count-number">
                      {CounterOn && (
                        <CountUp
                          start={0}
                          end={counter.target}
                          duration={2}
                          delay={0}
                        />
                      )}
                      {counter.showSign && "+"}
                    </h2>
                    <p className="count-text">{counter.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </div>
  );
};

export default CounterComponent;
