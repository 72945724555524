import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { data } from "../static/SiteData";
import "./NavDropDown.css";
import { Link } from "react-router-dom";
import { useState } from "react";

const ElementLink = ({ children, ...props }) => {
  return <Link {...props}>{children}</Link>;
};

const NavDropDown = ({ navbarBackground }) => {
  const [isHovered, setIsHovered] = useState({ show: false, ind: -1 });

  const handleMouseEnter = (index) => {
    setIsHovered({ show: true, ind: index });
  };

  const handleMouseLeave = (index) => {
    setIsHovered({ show: false, ind: index });
  };

  return (
    <DropdownButton
      style={navbarBackground ? { color: "black" } : { color: "white" }}
      title="Products"
      id="dropdown-menu"
      className="nav-item"
    >
      {data.map((item, index) => {
        if (item.childrens.length) {
          return (
            <Link to={`/featured/${encodeURI(item.name)}`}>
              <DropdownButton
                key={index}
                title={item.name}
                id="dropdown-menu-inside"
                drop={window.innerWidth <= 960 ? "down" : "end"}
                onMouseEnter={() => {
                  handleMouseEnter(index);
                }}
                onMouseLeave={() => {
                  handleMouseLeave(index);
                }}
                show={isHovered.ind == index ? isHovered.show : false}
              >
                {item.childrens.map((product, ind) => {
                  return (
                    <Dropdown.Item
                      key={ind}
                      eventKey={index + 1}
                      as={ElementLink}
                      to={`/product/${encodeURI(product.name)}`}
                    >
                      {product.name}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </Link>
          );
        } else {
          return (
            <Dropdown.Item
              eventKey={index + 1}
              as={ElementLink}
              to={`/featured/${encodeURI(data[index].name)}`}
              className="nav-links"
            >
              {item.name}
            </Dropdown.Item>
          );
        }
      })}
    </DropdownButton>
  );
};

export default NavDropDown;
