import "./CustomPage.css";
import "./MainPage.css";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "../components/AboutUs";
import Choose from "../components/Choose";
import CustomPage from "./CustomPage";
import { Button } from "../components/Button";
import FeaturedPageNavbar from "../components/FeaturedNav";
import Projects from "../components/ProjectsPage";

const MainPage = ({ item }) => {
  // console.log(item);
  const aboutRef = useRef(null);
  const chooseRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FeaturedPageNavbar
        aboutRef={aboutRef}
        chooseRef={chooseRef}
        item={item}
        childLen={item.childrens?.length}
        logo_path={item.logo_path}
      />
      <div
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        ref={aboutRef}
      >
        <div
          className="hero-section"
          style={{
            backgroundImage: "url(images/banner.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="hero-content">
            <h1 className="hero-heading">
              {item.mainHead.split("|").map((cur, index) => (
                <span
                  key={index}
                  style={{
                    color: index % 2 === 1 ? "var(--hover_color)" : "",
                  }}
                >
                  {cur}&nbsp;
                </span>
              ))}
            </h1>
            <p className="hero-paragraph">{item.description}</p>
            <Button
              className="btns"
              buttonStyle="btn--test"
              buttonSize="btn--large"
            >
              Explore Now
            </Button>
          </div>
          <div className="hero-image">
            <div className="laptop">
              <div className="laptop__screen">
                <img src={item.image} width="1600" height="1000" alt="Screen" />
              </div>
              <div className="laptop__bottom">
                <div className="laptop__under"></div>
              </div>
              <div className="laptop__shadow"></div>
            </div>
          </div>
        </div>
      </div>
      {item.childrens?.length === 0 && <AboutUs curData={item} />}
      {item.childrens?.length > 0 && (
        <Projects data={item.childrens} chooseRef={chooseRef} />
      )}
      {item.features.length > 0 && <Choose curData={item} />}
    </>
  );
};

export default MainPage;
