import "./Partners.css";

const Partners = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div data-aos="fade-up" data-aos-anchor-placement="top-center">
        <div id="partners_container">
          <div className="partners_container">
            <div className="container">
              {/* <div className="titlepage">
                <h2>Our Partners</h2>
              </div> */}
              <div className="col-sm-12 text-center">
                <h6>Our Partners</h6>
              </div>
              <div className="image-grid">
                <div className="image-row">
                  <div className="image image-01">
                    <a
                      href="https://haryana.coe-iot.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/Center of Excellence.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>

                  <div className="image image-02">
                    <a
                      href="https://www.aic-pinnacle.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/AIC- Pinnacle.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>

                  <div className="image image-03">
                    <a href="https://msins.in" target="_blank" rel="noreferrer">
                      <img
                        src="/images/MSInS_Logo.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <div className="image image-04">
                    <a
                      href="https://niti.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/NITI-AIM-Logo-600px.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <div className="image image-05">
                    <a
                      href="https://socialalpha.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/Social-Alpha.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <div className="image image-06">
                    <a
                      href="https://www.digitalimpactsquare.com/thirdi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/Digital-Impact-Square-300x129.png"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
