import React, { useState } from "react";
import "./AboutUs.css";

const PreviewParagraph = ({ text, wordLimit }) => {
  const [showButton, setShowButton] = useState(false);

  const truncateParagraph = (text, limit) => {
    const words = text.split(" ");

    if (words.length > limit) {
      const truncatedText = words.slice(0, limit).join(" ");
      return `${truncatedText}...`;
    }

    return text;
  };

  const handleButtonClick = () => {
    setShowButton(!showButton);
  };

  const truncatedText = truncateParagraph(text, wordLimit);
  return (
    <div>
      <p
        className={showButton ? "show" : "hide"}
        style={{ marginTop: "30px" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
      <p
        className={showButton ? "hide" : "show"}
        style={{ marginTop: "30px" }}
        dangerouslySetInnerHTML={{ __html: truncatedText }}
      ></p>
      <br />
      {truncatedText !== text && (
        <button
          className="btn btn-primary"
          style={{ borderRadius: "10px" }}
          onClick={handleButtonClick}
        >
          {showButton ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

const AboutUs = ({ pageRef, curData }) => {
  return (
    <>
      <div
        data-aos="flip-left"
        data-aos-delay="100"
        data-aos-anchor=".example-selector"
      >
        <section ref={pageRef} className="about-us" id="about-us">
          <div className="container">
            <div className="section-head col-sm-12">
              <h4>
                <span>About</span> Us
              </h4>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="column video"
                  data-aos="slide-right"
                  data-aos-easing="ease"
                  data-aos-duration="800"
                >
                  <div className="about-us-image">
                    <img src={curData?.about_us_image} alt="About Us Image" />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <h2 style={{ fontSize: "30px" }}>
                    {curData?.about_us_heading?.map((cur, index) => (
                      <span
                        key={index}
                        style={{
                          color: index % 2 === 1 ? "var(--hover_color)" : "",
                        }}
                      >
                        {cur}&nbsp;
                      </span>
                    ))}
                  </h2>
                </div>
                <div data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <PreviewParagraph
                    text={curData?.about_us_para}
                    wordLimit={150}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
