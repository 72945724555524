import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import { Button } from "./Button";

const Hero = () => {
  const handleScrollToElement = (id) => {
    const testNode = document.getElementById(id);

    if (testNode) {
      const offset = 95;
      const targetPosition =
        testNode.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // Adds smooth scrolling animation
      });
    }
  };
  return (
    <div className="hero-container" id="hero">
      {/* <video src="/videos/bg-video.mp4" autoPlay loop muted /> */}
      <img src="/images/man-touching-ai.svg" alt="" id="hero-img" />
      <div>
        <h1>Welcome to THIRD I</h1>
      </div>

      {/* <p className="hero_para">
        Third I is a flagship project of Adivid Technologies Pvt Ltd currently
        serving more than 15 customers with 55000+ users. The aim of Third I is
        to provide Real Time Information Transfer, Predict & Recommend decision
        based on Analytics & AI, Process Automation, Data Driven Alerts that are
        useful for the Government as well as Corporates.
      </p> */}

      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--test"
          buttonSize="btn--large"
          onClick={(e) => {
            e.preventDefault();
            handleScrollToElement("contact");
          }}
        >
          Request Demo
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={() => {
            handleScrollToElement("featured");
          }}
        >
          Our Products
        </Button>
      </div>
    </div>
  );
};

export default Hero;
