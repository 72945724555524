import "./App.css";
import React, { useEffect, useRef } from "react";
import Home from "./components/Home";
import CustomPage from "./pages/CustomPage";
import MainPage from "./pages/MainPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactFormWithMap from "./components/ContactFormWithMap";
import { data } from "./static/SiteData";

function App() {
  const homeRef = useRef(null);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {data
            .map(
              (item, index) =>
                item.childrens?.length &&
                item.childrens?.map((child, index) => (
                  <Route
                    key={index}
                    path={`product/${child.name}`}
                    element={
                      <CustomPage item={child} logo_path={item.logo_path} />
                    }
                  />
                ))
            )
            .flat()
            .filter(Boolean)}

          {data.map((item, index) => (
            <Route
              key={index}
              path={`featured/${item.name}`}
              element={<MainPage item={item} />}
            />
          ))}
          <Route
            path="/"
            exact
            element={<Home homeRef={homeRef} data={data} />}
          />
        </Routes>
        <ContactFormWithMap />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
