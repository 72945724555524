import "./CustomPage.css";
import AboutUs from "../components/AboutUs";
import Choose from "../components/Choose";
import { useRef, useEffect } from "react";
import { Button } from "../components/Button";
import PageNavbar from "../components/PageNavbar";

const CustomPage = ({ item, logo_path }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNavbar logo_path={logo_path} />
      <div
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div
          className="hero-section"
          style={{
            backgroundImage: "url(images/banner.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="hero-content">
            <h1 className="hero-heading">
              {item.splitName?.map((cur, index) => (
                <span
                  key={index}
                  style={{
                    color: index % 2 === 1 ? "var(--hover_color)" : "",
                  }}
                >
                  {cur}&nbsp;
                </span>
              ))}
            </h1>
            <p className="hero-paragraph">{item.description}</p>
            <Button
              className="btns"
              buttonStyle="btn--test"
              buttonSize="btn--large"
            >
              Request Demo
            </Button>
          </div>
          <div className="hero-image">
            <div class="laptop">
              <div class="laptop__screen">
                <img
                  src={item.images[0]}
                  width="1600"
                  height="1000"
                  alt="Screen"
                />
              </div>
              <div class="laptop__bottom">
                <div class="laptop__under"></div>
              </div>
              <div class="laptop__shadow"></div>
            </div>
          </div>
        </div>
      </div>

      <AboutUs curData={item} />
      {item.features.length > 0 && <Choose curData={item} />}
    </>
  );
};

export default CustomPage;
