import React from "react";
import Hero from "./Hero";
import Partners from "./Partners";
import Awards from "./Awards";
import Featured from "./Featured";
import Navbar from "./Navbar";
import CounterComponent from "./CounterComponent";
import ArticleNewsCard from "./ArticleNewsCard";

const Home = ({ homeRef, data }) => {
  return (
    <>
      <Navbar homeRef={homeRef} />
      <Hero />
      <Featured data={data} />
      <CounterComponent />
      {/* <ArticleNewsCard /> */}
      <Awards />
      <Partners />
    </>
  );
};

export default Home;
