export const data = [
  {
    name: "Third I: Insight Analytics",
    mainHead: "Third I: | Insight Analytics",
    logo_path: "images/logos/insight_analytics.png",
    description: (
      <p>
        Digital platform enhancing governance through seamless real-time
        transfer of information, advanced Data analytics, and AI technologies.
      </p>
    ),
    image: "images/1_THIRD I reporting.jpg",
    logo: "images/Third_I_Footer_JPG.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Insight Analytics"],
    about_us_para: ``,
    childrens: [
      {
        name: "Automated Report Generation & Analytics Module",
        splitName: [
          "Automated",
          "Report",
          "Generation &",
          "Analytics",
          "Module",
        ],
        description: (
          <p>
            our crime reporting platform represents a significant advancement in
            law enforcement efficiency.
          </p>
        ),
        image: "",
        images: [
          "images/automated_reporting_1.jpg",
          "images/automated_reporting_2.jpg",
        ],
        bgSiteImg: "images/1.1.jpeg",
        about_us_image: "images/Reporting.png",
        about_us_heading: [
          "Automated",
          "Report",
          "Generation &",
          "Analytics",
          "Module",
        ],
        about_us_para: `
          <p>
            "Our platform centralizes a vast amount of previously untapped data,
            unlocking its potential for analysis and decision-making. It
            automates more than 15 crucial proceedings and provides officers
            with access to over 30 analytical charts and graphs. This wealth of
            information enables them to make informed and intelligent decisions
            in their law enforcement efforts. Currently, our platform is
            successfully being utilized by 15 police departments, with over
            35,000 active users benefiting from its features and capabilities."
            
          </p>
        `,
        features: [
          {
            icon: "images/icons/report (1).png",
            heading: "Automated Reporting",
            para: (
              <p>
                The platform generates 20+ crime reports in near real-time,
                saving manpower and reducing redundancies.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/market-research.png",
            heading: "Multilevel Analytics",
            para: (
              <p>
                This module provides advanced analytical tool for law
                enforcement, processing data from FIR, AD, Arrested lists, and
                more.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/testimonial.png",
            heading: "Digital Panchnama",
            para: (
              <p>
                The mobile app simplifies Punchnama by capturing location
                details, photos videos, and witness statements electronically
                for officers.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/danger.png",
            heading: "Crime Hotspot Identification",
            para: (
              <p>
                By analyzing data from multiple investigations, our solution
                identifies areas with a high incidence of crime, enabling
                proactive measures to address the root causes and mitigate
                future criminal activities.
              </p>
            ),
          },
          {
            icon: "images/icons/map.png",
            heading: "Area-wise Crime Intensity and Safety Ratings",
            para: (
              <p>
                Our solution provides detailed analytics and generates area-wise
                crime intensity reports and safety ratings.
              </p>
            ),
          },
        ],
      },
      {
        name: "GIS Analytics Module",
        splitName: ["GIS", "Analytics", "Module"],
        description: (
          <p>
            This module is dedicated to generating valuable insights and
            recommendations by leveraging advanced GIS-based data analysis.
          </p>
        ),
        image: "",
        images: ["images/GIS_1.jpg"],
        bgSiteImg: "images/1.2.jpeg",
        about_us_image: "images/GIS.png",
        about_us_heading: ["GIS", "Analytics", "Module"],
        about_us_para: `
          <p>
          "The goal is to understand and predict crime criticality, allowing law enforcement
           agencies to make informed decisions and take proactive measures. 
           It provides a comprehensive display of various types of information 
           crucial for crime analysis, including crime data, criminal activity hotspots,
           accident-prone areas, shipping zones, religious places, nakabandi (checkpoints),
           and vital installations."
 
          </p>
        `,
        features: [
          {
            icon: "images/icons/predictive-analysis.png",
            heading: "Advanced GIS-based Data Analysis",
            para: (
              <p>
                Our solution employs advanced geographic information system
                (GIS) technology to analyze and visualize crime-related data.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/danger_gis.png",
            heading: "Crime Hotspots and Criminal Activity Visualization",
            para: (
              <p>
                Through our platform, law enforcement agencies can easily
                identify crime hotspots and visualize criminal activity patterns
                on a map.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/location.png",
            heading: "Comprehensive Location-based Information",
            para: (
              <p>
                -Integrating diverse location-based data sources offers a
                holistic view of the environment, enabling law enforcement to
                have a better understanding of the local landscape.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/insight.png",
            heading: "Insights and Recommendations",
            para: (
              <p>
                By analyzing the collected data and applying advanced
                algorithms, our solution generates meaningful insights and
                recommendations.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Police Profiling Module",
        splitName: ["Police", "Profiling", "Module"],
        description: (
          <p>
            This module enables efficient human resource management within the
            police administration. <br /> This digital platform maintains
            detailed records of each police personnel, creating a repository
            that includes essential information.
          </p>
        ),
        image: "",
        images: ["images/police_profiling.jpg"],
        bgSiteImg: "images/1.3.jpeg",
        about_us_image: "images/Profiling.png",
        about_us_heading: ["Police", "Profiling", "Module"],
        about_us_para: `
          <p>
            This digital platform maintains detailed records of each police
            personnel, creating a repository that includes essential information
            such as basic details, postings, promotions, training, medical
            reports, achievements, remarks, and other pertinent records. It
            empowers police administration with a robust digital infrastructure
            for managing personnel records. 
          </p>
        `,
        features: [
          {
            icon: "images/icons/personal-information (1).png",
            heading: "Digital Personnel Records",
            para: (
              <p>
                Our platform efficiently stores and manages extensive
                information related to police personnel.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/optimization.png",
            heading: "Optimal Human Resource Utilization",
            para: (
              <p>
                By digitizing and organizing personnel records, our platform
                allows police administration to effectively utilize their human
                resources..{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/rm.png",
            heading: "Resource Management Insights",
            para: (
              <p>
                The digital repository of personnel data allows for the
                identification of trends in performance, training, and other
                relevant factors.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Tenant Verification Module",
        splitName: ["Tenant", "Verification", "Module"],
        description: (
          <p>
            The Tenant Verification System is an innovative initiative with the
            potential to enhance the safety and security of rental communities
            by allowing property owners to conduct online police verification of
            tenants.
          </p>
        ),
        image: "",
        images: ["images/tenant_verification.jpg"],
        bgSiteImg: "images/1.4.jpeg",
        about_us_image: "images/Tenant.png",
        about_us_heading: ["Tenant", "Verification", "Module"],
        about_us_para: `
          <p>
            This system eliminates the need for owners or agents to physically
            visit the police station by providing a convenient online platform.
            It improves the tenant verification process by digitizing and
            streamlining the traditionally time-consuming and cumbersome
            procedure. 
          </p>
        `,
        features: [
          {
            icon: "images/icons/card.png",
            heading: "Online Verification Process",
            para: (
              <p>
                Users, such as property owners and agents, can quickly submit
                tenant details through the platform, making the verification
                process faster and more efficient.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/two-way-communication.png",
            heading: "Direct Communication",
            para: (
              <p>
                The system enables direct communication, eliminating the need
                for physical visits in tenant verification..{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/tracking.png",
            heading: "Application Status Tracking",
            para: (
              <p>
                Applicants receive a unique token to track verification status
                in the application.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Court Monitoring Module",
        splitName: ["Court", "Monitoring", "Module"],
        description: (
          <p>
            This module serves as a comprehensive and robust solution for the
            Police Department, offering a range of features to streamline the
            management of court proceedings.
          </p>
        ),
        image: "",
        images: ["images/court monitoring.jpg"],
        bgSiteImg: "images/1.5.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Court", "Monitoring", "Module"],
        about_us_para: `
          <p>
          The platform is a robust Content Management System designed for efficient court proceedings management. 
          It tracks charges, evidence, summons, warrants, judgments, and criminal records, enhancing law enforcement
          effectiveness through streamlined record-keeping and proactive measures.
        `,
        features: [
          {
            icon: "images/icons/info.png",
            heading: "Court Information Management",
            para: (
              <p>
                The system efficiently manages and maintains court-related
                information in a centralized platform.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/tracking-app.png",
            heading: "Tracking of Convicts and Criminals",
            para: (
              <p>
                By integrating court-related information with offender records,
                the system enables police departments to track and monitor
                convicts and criminals.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/business.png",
            heading: "Witness Summons and Preventive Actions",
            para: (
              <p>
                The system efficiently manages witness summoning, ensuring
                timely appearances and supporting legal proceedings' integrity.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/market-research.png",
            heading: "Data Analysis and Insights",
            para: (
              <p>
                The system enables police departments to analyze court-related
                data, generate reports, and identify patterns for informed
                decision-making and resource allocation.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Criminal Surveillance Module",
        splitName: ["Criminal", "Surveillance", "Module"],
        description: (
          <p>
            Our platform aims to enhance policing efforts by leveraging
            technology to streamline criminal database management. <br />
          </p>
        ),
        image: "",
        images: [
          "images/Criminal Surveillance.jpg",
          "images/Criminal Surveillance_2.jpg",
        ],
        bgSiteImg: "images/1.6.jpeg",
        about_us_image: "images/Criminal 1.png",
        about_us_heading: ["Criminal", "Surveillance", "Module"],
        about_us_para: `
          <p>
            Our platform comprises a mobile app and dashboard designed to
            streamline criminal database management for each police station. The
            system allows police stations to input and maintain comprehensive
            details of known criminals, including their current residency (GPS
            coordinates), photographs, and other relevant information. This
            database serves as a valuable resource for efficient patrolling,
            identifying potential criminals during new crime registrations, and
            leveraging facial recognition technology for identification and
            recognition purposes.
          </p>
        `,
        features: [
          {
            icon: "images/icons/dashboard.png",
            heading: "Mobile App and Dashboard",
            para: (
              <p>
                The platform features a user-friendly mobile app and dashboard
                for police stations to manage the criminal database.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/patrol.png",
            heading: "Efficient Patrolling",
            para: (
              <p>
                By leveraging the criminal database and GPS data, law
                enforcement personnel can optimize patrolling activities.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/electronics.png",
            heading: "Identification and Recognition",
            para: (
              <p>
                The platform integrates facial recognition technology to aid in
                the identification and recognition of individuals involved in
                criminal activities.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/profile.png",
            heading: "Digital Profiles and Legal Compliance",
            para: (
              <p>
                The platform ensures the creation and maintenance of digital
                profiles for each known offender, adhering to legal requirements
                and protocols.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Control Room Call Analytics Module",
        splitName: ["Control", "Room", "Call", "Analytics", "Module"],
        description: (
          <p>
            The module serves as a valuable tool for analyzing emergency calls,
            identifying patterns, and making data-driven decisions to enhance
            emergency management.
          </p>
        ),
        image: "",
        images: ["images/control_room.jpg"],
        bgSiteImg: "images/1.7.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Control", "Room", "Call", "Analytics", "Module"],
        about_us_para: `
          <p>
            This module is a powerful tool for analyzing daily, monthly, and
            yearly emergency calls. It provides valuable insights by examining
            various parameters such as call/complaint types, time-wise
            distribution, call durations, call frequencies, actions taken,
            response times, and other relevant information. The module empowers
            Control Room Operators to make informed decisions and enhancements
            based on these insights.
          </p>
        `,
        features: [
          {
            icon: "images/icons/analysis.png",
            heading: "Comprehensive Call Analysis",
            para: (
              <p>
                The module analyzes emergency calls, categorizing them by type
                to provide valuable insights for Control Room Operators into the
                nature and patterns of emergencies{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/data-analysis.png",
            heading: "Time-Wise Analysis",
            para: (
              <p>
                The module analyzes emergency call patterns over time, aiding
                resource allocation for efficient response during peak periodsy.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/telephone.png",
            heading: "Call Duration and Frequency",
            para: (
              <p>
                The module provides information on the duration and frequency of
                emergency calls, allowing operators to identify potential trends
                or anomalies.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/response.png",
            heading: "Action Taken and Response Time",
            para: (
              <p>
                The module tracks emergency response actions and measures time,
                essential for evaluating and improving the efficiency of
                emergency services and management.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/decision-making.png",
            heading: "Informed Decision-Making",
            para: (
              <p>
                By consolidating and presenting relevant information in a clear
                and organized manner, the module empowers Control Room Operators
                to make informed decisions.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Cyber Awareness Module",
        splitName: ["Cyber", "Awareness", "Module"],
        description: (
          <p>
            The Cyber Awareness Application is a comprehensive tool designed to
            raise awareness about cyber security threats among individuals of
            all age groups.
          </p>
        ),
        image: "",
        images: ["images/cyber_aware.png", "images/18_Cyber1.jpg"],
        bgSiteImg: "images/1.8.jpeg",
        about_us_image: "images/Cyber.png",
        about_us_heading: ["Cyber", "Awareness", "Module"],
        about_us_para: `
          <p>
          The Cyber Awareness App educates users on cybercrimes through interactive
          quizzes and informative content. It promotes cybersecurity awareness for all ages,
          encouraging active learning and creating a safer digital environment.
          </p>
        `,
        features: [
          {
            icon: "images/icons/quiz.png",
            heading: "Multistage-Level Quizzes",
            para: (
              <p>
                The application engages users through multistage-level quizzes
                that cover various aspects of cyber security.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/info (1).png",
            heading: "Tips and Information",
            para: (
              <p>
                The application provides valuable tips, guidelines, and
                information about best practices for staying safe online.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/performance.png",
            heading: "User Performance Tracking",
            para: (
              <p>
                The application tracks and evaluates user performance on a
                regular basis. It monitors quiz results, knowledge improvement,
                and user engagement to provide personalized feedback.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/certificate.png",
            heading: "Certificates",
            para: (
              <p>
                The application offers certificates to users who successfully
                complete specific levels or achieve certain milestones in the
                cyber security awareness program.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/best-customer-experience.png",
            heading: "User Ratings",
            para: (
              <p>
                The application may incorporate a user rating system, allowing
                users to rate their experience and provide feedback.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Naxalism Prevention Module",
        splitName: ["Naxalism", "Prevention", "Module"],
        description: (
          <p>
            The Cyber Awareness Application is a comprehensive tool designed to
            raise awareness about cyber security threats among individuals of
            all age groups.
          </p>
        ),
        image: "",
        images: ["images/cyber_aware.png", "images/18_Cyber1.jpg"],
        bgSiteImg: "images/1.9.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Cyber", "Awareness", "Module"],
        about_us_para: `
          <p>
          The Cyber Awareness App educates users on cybercrimes through interactive
          quizzes and informative content. It promotes cybersecurity awareness for all ages,
          encouraging active learning and creating a safer digital environment.
          </p>
        `,
        features: [
          {
            icon: "images/icons/quiz.png",
            heading: "Multistage-Level Quizzes",
            para: (
              <p>
                The application engages users through multistage-level quizzes
                that cover various aspects of cyber security.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/info (1).png",
            heading: "Tips and Information",
            para: (
              <p>
                The application provides valuable tips, guidelines, and
                information about best practices for staying safe online.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/performance.png",
            heading: "User Performance Tracking",
            para: (
              <p>
                The application tracks and evaluates user performance on a
                regular basis. It monitors quiz results, knowledge improvement,
                and user engagement to provide personalized feedback.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/certificate.png",
            heading: "Certificates",
            para: (
              <p>
                The application offers certificates to users who successfully
                complete specific levels or achieve certain milestones in the
                cyber security awareness program.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/best-customer-experience.png",
            heading: "User Ratings",
            para: (
              <p>
                The application may incorporate a user rating system, allowing
                users to rate their experience and provide feedback.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "THIRD I V2.0: Vigilance Module",
        splitName: ["THIRD I V2.0", "Vigilance", "Module"],
        description: (
          <p>
            The Digital Surveillance System is designed to provide the Special
            Branch os State Intelligence Department (SID) with advanced
            surveillance and monitoring capabilities, leveraging digital
            technology to enhance their operations.
          </p>
        ),
        image: "",
        images: ["images/17_THIRD I vigilance.jpg"],
        bgSiteImg: "images/1.10.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["THIRD I V2.0", "Vigilance", "Module"],
        about_us_para: `
          <p>
            The Digital Surveillance System enhances the Special Branch's capabilities 
            by leveraging advanced surveillance technology. This comprehensive solution
            encompasses various aspects of surveillance, digitizing and
            streamlining processes across civil and policing activities. It
            improves situational awareness, enables proactive measures, and
            supports effective law enforcement efforts.
          </p>
        `,
        features: [
          {
            icon: "images/icons/technology.png",
            heading: "Digital Surveillance Records",
            para: (
              <p>
                Digital surveillance records eliminate the need for cumbersome
                manual documentation.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/monitor (1).png",
            heading: "Monitoring Various Organizations and Individuals",
            para: (
              <p>
                The system monitors diverse entities like labor organizations,
                Naxalites, and politicians, providing real-time insights for the
                Special Branch to take prompt actions as needed.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/shield.png",
            heading: "VIP Tour Management",
            para: (
              <p>
                The system supports the surveys, management, and maintenance of
                VIP tours and activities related to VIP protection.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/gis.png",
            heading: "Vital Installations and GIS Locations Surveillance",
            para: (
              <p>
                The system uses GIS technology to surveil critical
                infrastructure and important locations.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/online-registration.png",
            heading: "Monitoring Foreign Nationals and Immigrants",
            para: (
              <p>
                The system enables digital reporting of information related to
                foreign nationals and immigrants.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/monitoring.png",
            heading: "Agitation and Media Monitoring",
            para: (
              <p>
                The system gathers info from agitations, strikes, social media,
                and news, identifying threats and providing real-time alerts to
                the Special Branch.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "THIRD I V3.0: Predictive Analytical Module",
        splitName: ["THIRD I V3.0:", "Predictive", "Analytical", "Module"],
        description: (
          <p>
            The Version 3 of Third I has been developed with latest modern
            technologies to help Police to get deep into the Data and generate
            advanced Data Analytics, Machine Learning and AI based
            Recommendations. The aim of this endeavor was to embed an ML based
            Patrolling System which will guide police to take the optimal route,
            deploy resources and take action based on the Real-Time Crime Data.
          </p>
        ),
        image: "",
        images: ["images/v3 Predictive.png"],
        bgSiteImg: "images/1.11.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: [
          "THIRD I V3.0:",
          "Predictive",
          "Analytical",
          "Module",
        ],
        about_us_para: `
        <p>
        The Version 3 of Third I has been developed with latest modern
        technologies to help Police to get deep into the Data and generate
        advanced Data Analytics, Machine Learning and AI based
        Recommendations. The aim of this endeavor was to embed an ML based
        Patrolling System which will guide police to take the optimal route,
        deploy resources and take action based on the Real-Time Crime Data.
      </p>
        `,
        features: [],
      },
      {
        name: "THIRD I STATE: Analytical Dashboard for State",
        splitName: ["THIRD I STATE:", "Analytical", "Dashboard", "for State"],
        description: (
          <p>
            This Project is built to provide a state level live Dashboard to
            visualize the crimes in the entire state by the relevant state
            authority, meanwhile it also provides a centralized Data Platform
            for the state on a single click. We developed it with making Data
            sharing partnership with WIPRO's CCTNS (Crime & Criminal Tracking
            Network & System) where they provided APIs for relevant State Crime
            Data.
          </p>
        ),
        image: "",
        images: ["images/Third I CCTNS.png"],
        bgSiteImg: "images/1.12.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: [
          "THIRD I STATE:",
          "Analytical",
          "Dashboard",
          "for State",
        ],
        about_us_para: `
          <p>
            This Project is built to provide a state level live Dashboard to
            visualize the crimes in the entire state by the relevant state
            authority, meanwhile it also provides a centralized Data Platform
            for the state on a single click. We developed it with making Data
            sharing partnership with WIPRO's CCTNS (Crime & Criminal Tracking
            Network & System) where they provided APIs for relevant State Crime
            Data
          </p>
        `,
        features: [],
      },
      {
        name: "Case Reporting System: Childline India",
        splitName: ["Case Reporting", "System:", "Childline India"],
        description: (
          <p>
            This innovative platform seeks to revolutionize the case reporting
            procedures of Childline India 1098 and its partners, NGOs, and other
            stakeholders engaged in addressing offenses against children.
          </p>
        ),
        image: "",
        images: ["images/childline.png"],
        bgSiteImg: "images/1.13.jpeg",
        about_us_image: "images/Childline2.png",
        about_us_heading: ["Case Reporting", "System:", "Childline India"],
        about_us_para: `
          <p>
            It achieves this by digitizing, automating, and enhancing the entire
            workflow system, utilizing the latest technologies, real-time
            information flow, and advanced data management capabilities. It
            consist of an mobile App and a Role & Access based Dashboard to
            create, edit and receive cases against children and taking follow up
            procedures in a robust manner digitally.
          </p>
        `,
        features: [
          {
            icon: "images/icons/system.png",
            heading: "Digitization and Automation",
            para: (
              <p>
                The platform digitizes and automates Childline India's workflow,
                eliminating manual and paper-based processes for increased
                efficiency.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/technology2.png",
            heading: "Latest Technologies",
            para: (
              <p>
                The platform leverages cutting-edge technologies to enhance the
                capabilities of Childline stakeholders.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/information.png",
            heading: "Real-Time Information Flow",
            para: (
              <p>
                The platform enables quick communication and collaboration among
                Childline stakeholders for faster response in cases of offenses
                against children.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/data-management.png",
            heading: "Data Management",
            para: (
              <p>
                Advanced data management capabilities are integrated into the
                platform, allowing for secure storage, organization, and
                analysis of critical information.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/decision-making (1).png",
            heading: "Empowerment and Decision-Making",
            para: (
              <p>
                The platform empowers stakeholders with advanced technologies,
                enabling quick, informed, and proactive decision-making.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Case Reporting System: MyAmbar SurakshaChakra",
        splitName: ["Case Reporting", "System:", "MyAmbar", "SurakshaChakra"],
        description: (
          <p>
            MyAmbar Suraksha Chakra is an innovative online platform designed to
            empower women and ensure a safe working environment by facilitating
            the filing of complaints regarding sexual harassment in the
            workplace.
          </p>
        ),
        image: "",
        images: ["images/childline.png"],
        bgSiteImg: "images/1.14.jpeg",
        about_us_image: "images/My ambar.png",
        about_us_heading: [
          "Case Reporting",
          "System:",
          "MyAmbar",
          "SurakshaChakra",
        ],
        about_us_para: `
          <p>
          By leveraging the power of technology, MyAmbar Suraksha Chakra aims to create 
          a safe and supportive environment for working women. It encourages prompt 
          reporting, effective resolution, and proactive measures to prevent sexual 
          harassment in workplaces. The platform contributes to the overall goal of 
          fostering gender equality and ensuring the dignity and well-being of women in 
          the workforce.

          {" "}
          </p>
        `,
        features: [
          {
            icon: "images/icons/t.png",
            heading: "Complaint Filing",
            para: (
              <p>
                The platform enables women to file complaints of sexual
                harassment at their workplace through a user-friendly interface.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/relationship.png",
            heading: "Local Committee Engagement",
            para: (
              <p>
                The platform facilitates seamless communication and
                collaboration between aggrieved women and Local Committees.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/time-left.png",
            heading: "Time-Bound Resolution",
            para: (
              <p>
                MyAmbar Suraksha Chakra emphasizes adherence to the timelines
                set by law for resolving complaints of sexual harassment.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/data-management.png",
            heading: "Data Management and Reporting",
            para: (
              <p>
                The platform maintains a secure and confidential database of
                complaints and related information.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/resource.png",
            heading: "Support and Resources",
            para: (
              <p>
                MyAmbar Suraksha Chakra provides access to resources,
                guidelines, and educational materials related to sexual
                harassment at the workplace.{" "}
              </p>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Third I: Information-Driven Surveillance",
    mainHead: "Third I: | Information-Driven | Surveillance",
    logo_path: "images/logos/third_i_Information_Driven_Surveillance.jpg",
    description: (
      <p>
        Platform employing analytics & AI to optimize patrolling, surveillance,
        and resource allocation, thus elevating public saftey.
      </p>
    ),
    image: "images/Vehicle Tracking.png",
    logo: "images/sample_img.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Information-Driven", "Surveillance"],
    about_us_para: ``,
    childrens: [
      {
        name: "Tracking & Monitoring Application",
        splitName: ["Tracking &", "Monitoring", "Application"],
        description: (
          <p>
            The dashboard boosts real-time oversight of crime with an Android
            app for efficient patrol tracking by the police control room,
            facilitating incident reporting and seamless communication.
          </p>
        ),
        image: "",
        images: ["images/Vehicle Tracking.png", "images/Vehicle Tracking.png"],
        bgSiteImg: "images/2.1.jpeg",
        about_us_image: "images/Traffic monitoring.png",
        about_us_heading: ["Tracking &", "Monitoring", "Application"],
        about_us_para: `
          <p>
          The dashboard serves as a centralized platform where incident reports and 
          SOS/help calls from citizens are displayed in real-time. This comprehensive
          overview allows for immediate response and coordination by law enforcement
          authorities. The dashboard enhances situational awareness, improves response 
          times, and fosters effective collaboration between the control room, police 
          officers, and citizens.

          </p>
        `,
        features: [
          {
            icon: "images/icons/tracking (1).png",
            heading: "Patrolling Personnel Tracking",
            para: (
              <p>
                Police officers can swiftly report incident details through the
                application, ensuring efficient data collection and sharing
                among relevant personnel.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/report (1).png",
            heading: "Incident Reporting",
            para: (
              <p>
                This module provides advanced analytical tool for law
                enforcement, processing data from FIR, AD, Arrested lists, and
                more.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/avatar.png",
            heading: "SOS/Help Calls",
            para: (
              <p>
                The app allows distress calls to be sent, promptly displayed on
                the dashboard for swift police response and assistance.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "QR Patrolling Application",
        splitName: ["QR", "Patrolling", "Application"],
        description: (
          <p>
            Our mobile application is designed to provide systematic tracking of
            beat marshals and other patrolling vehicles, offering enhanced
            efficiency and security.
          </p>
        ),
        image: "",
        images: ["images/6_QR Patrolling.jpg"],
        bgSiteImg: "images/2.2.jpeg",
        about_us_image: "images/QR Patrolling 2.png",
        about_us_heading: ["QR", "Patrolling", "Application"],
        about_us_para: `
          <p>
          Our mobile application aims to enhance the efficiency, security, and
          decision-making capabilities of beat marshals and patrolling units. 
          By leveraging digital technologies and real-time tracking, we promote 
          a more proactive and responsive approach to ensuring public safety and security.
          </p>
        `,
        features: [
          {
            icon: "images/icons/qr-code.png",
            heading: "QR Code Scanning",
            para: (
              <p>
                The application allows beat marshals and patrolling vehicles to
                scan unique QR codes assigned to highly sensitive areas.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/map-location.png",
            heading: "Real-Time GPS Location",
            para: (
              <p>
                The mobile application displays the real-time GPS location of
                beat marshals and patrolling vehicles on the Control Room
                dashboard.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/interaction.png",
            heading: "Enhanced On-Duty Experience",
            para: (
              <p>
                The mobile application provides an improved on-duty experience
                for beat marshals and patrolling personnel.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/empowerment.png",
            heading: "Control Room Empowerment",
            para: (
              <p>
                The Control Room dashboard empowered by our mobile application
                facilitates quick decision-making during emergencies.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Custom Duty Application",
        splitName: ["Custom", "Duty", "Application"],
        description: (
          <p>
            This mobile based application allows users/service personnels to
            post their duties, which includes, Geo-locations, Media information
            and other details as per the service entails.
          </p>
        ),
        image: "",
        images: ["images/Logistics Tracking.png"],
        bgSiteImg: "images/2.3.jpeg",
        about_us_image: "images/Custom duty.png",
        about_us_heading: ["Custom", "Duty", "Application"],
        about_us_para: `
          <p>
            This mobile based application allows users/service personnels to
            post their duties, which includes, Geo-locations, Media information
            and other details as per the service entails. The Dashboard
            systematically shows the duty of all the individuals and generates
            comprehensive reports.
          </p>
        `,
        features: [],
      },
      {
        name: "Suraksha Project (Taxi Surveillance & SOS Application)",
        splitName: [
          "Suraksha",
          "Project",
          "(Taxi Surveillance &",
          "SOS Application)",
        ],
        description: (
          <p>
            Our portal serves as a digital registration platform for taxis and
            rickshaws, capturing comprehensive information about the vehicles
            and their owners.
          </p>
        ),
        image: "",
        images: ["images/Suraksha.jpg"],
        bgSiteImg: "images/2.4.jpeg",
        about_us_image: "images/Suraksha project.png",
        about_us_heading: [
          "Suraksha",
          "Project",
          "(Taxi Surveillance &",
          "SOS Application)",
        ],
        about_us_para: `
          <p>
          This digitization process supports the Traffic Branch and RTO in maintaining
           accurate records and efficiently managing the validity of permits and fitness
           certifications for both drivers and vehicles. By digitalizing registration
           processes and implementing a unique QR code system, our portal enhances
           record-keeping, accountability, and security in the taxi and rickshaw industry. 
           It strengthens the relationship between drivers, passengers, and authorities,
           promoting safer and more reliable transportation services.

          </p>
        `,
        features: [
          {
            icon: "images/icons/digital-identity.png",
            heading: "Digital Registration and Record-Keeping",
            para: (
              <p>
                Our portal enables the digital registration of taxis and
                rickshaws, providing a centralized database of vehicle and owner
                records.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/qr.png",
            heading: "Unique QR Code System",
            para: (
              <p>
                Each driver is assigned a unique QR code that serves as an
                identification tool for their actions while on duty.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/safety.png",
            heading: "Citizen Engagement and Security",
            para: (
              <p>
                Our portal enhances citizen security by allowing passengers to
                scan the QR code of the driver they are traveling with.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Eagle Eye: Coastal Security Platform",
        splitName: ["Eagle Eye:", "Coastal", "Security", "Platform"],
        description: (
          <p>
            Eagle Eye enhances coastal security, enabling agencies like the
            coast guard and Coastal Police to monitor and track registered boats
            within their jurisdiction.
          </p>
        ),
        image: "",
        images: ["images/21_Coastal_Security.jpg"],
        bgSiteImg: "images/2.5.jpeg",
        about_us_image: "images/Eagle eye.png",
        about_us_heading: ["Eagle Eye:", "Coastal", "Security", "Platform"],
        about_us_para: `
          <p>
            Eagle Eye is a comprehensive platform designed to enhance coastal
            security by enabling various coastal security agencies, such as the
            coast guard, Sagari-Suraksha Dal, and Coastal Police, to monitor and
            track registered boats within their jurisdiction. The
            platform consists of a web portal and a mobile application, offering
            a range of features and capabilities to support effective sea
            patrolling and prevent illegal activities in coastal areas.
           
            </p>
        `,
        features: [
          {
            icon: "images/icons/registration.png",
            heading: "Boat Registration",
            para: (
              <p>
                The platform simplifies boat registration in coastal areas,
                providing unique IDs for easy tracking and identification.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/info (1).png",
            heading: "Sailor Information",
            para: (
              <p>
                Along with boat registration, the platform captures and
                maintains information about boat owners and sailors.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/map.png",
            heading: "Geo-Coded Mapping",
            para: (
              <p>
                The platform incorporates geo-coded mapping, enabling security
                agencies to visualize the location of registered boats on a map.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/monitor.png",
            heading: "Real-Time Monitoring",
            para: (
              <p>
                Eagle Eye enables real-time monitoring of registered boats,
                ensuring compliance and identifying potential security threats
                for security agencies.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/danger (1).png",
            heading: "Alert System",
            para: (
              <p>
                The platform includes an alert system that notifies security
                agencies of any unauthorized or suspicious activities related to
                registered boats.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/analysis (1).png",
            heading: "Data Analysis and Reporting",
            para: (
              <p>
                Eagle Eye provides data analysis and reporting capabilities,
                allowing security agencies to generate insights and reports
                based on boat movements, patterns, and other relevant data.{" "}
              </p>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Third I: Traffic Insights",
    mainHead: "Third I: | Traffic Insights",
    logo_path: "images/logos/traffic_insight.png",
    description: (
      <p>
        This initiative leverages detailed accident analysis, Real-time traffic
        monitoring & AI to enhance road saftey and traffic management.
      </p>
    ),
    image: "images/12_accident sanrakshak.jpg",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Traffic", "Insights"],
    about_us_para: ``,
    childrens: [
      {
        name: "Accident Prevention and Road Safety Platform",
        splitName: ["Accident", "Prevention", "and", "Road Safety", "Platform"],
        description: (
          <p>
            This platform is designed to alleviate the occurrence of accidents
            and enhance overall saftey on roads.The goal is to identify and
            address factors contributing to accidents.
          </p>
        ),
        image: "",
        images: ["images/7_Accident.jpg"],
        bgSiteImg: "images/3.1.jpeg",
        about_us_image: "images/accident.png",
        about_us_heading: [
          "Accident",
          "Prevention",
          "and",
          "Road Safety",
          "Platform",
        ],
        about_us_para: `
          <p>
            Our dashboard offers comprehensive multilayer analysis of both major
            and minor road accidents, including injuries, with a primary focus
            on identifying accident blackspots. This powerful tool empowers
            traffic branch administration to take directed and informed actions
            for improving road safety.
          </p>
        `,
        features: [
          {
            icon: "images/icons/investigation.png",
            heading: "Accident Analysis",
            para: (
              <p>
                Our dashboard conducts in-depth analysis of road accidents,
                considering both major and minor incidents.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/map.png",
            heading: "Identification of Accident Blackspots",
            para: (
              <p>
                Through advanced data analysis techniques, our dashboard
                identifies accident blackspots—areas with a high concentration
                of accidents.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/file.png",
            heading: "Traffic Offense Reporting",
            para: (
              <p>
                The daily traffic offense report identifies repeat offenders,
                aiding authorities in targeted enforcement and promoting
                responsible driving behavior.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/actionable.png",
            heading: "Directed and Informed Actions",
            para: (
              <p>
                Our dashboard provides data-driven insights for traffic branch
                administration to make informed decisions and enhance road
                safety. safety.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Traffic Monitoring and Analytics Dashboard",
        splitName: ["Traffic", "Monitoring and", "Analytics", "Dashboard"],
        description: (
          <p>
            This initiative empowers the traffic department with real-time
            visualization and intelligent recommendations for improved traffic
            flow and informed decision-making.
          </p>
        ),
        image: "",
        images: ["images/Live Traffic Application.jpg"],
        bgSiteImg: "images/3.2.jpeg",
        about_us_image: "images/Traffic monitoring2.png",
        about_us_heading: [
          "Traffic",
          "Monitoring and",
          "Analytics",
          "Dashboard",
        ],
        about_us_para: `
          <p>
            The platform provides live traffic condition updates, alerts, and
            notifications to relevant officers in the vicinity, ensuring timely
            response to traffic congestion and incidents. <br /> By leveraging
            real-time data, machine learning, and collaborative decision-making,
            this initiative empowers the traffic department to proactively
            manage traffic conditions, reduce congestion, and enhance overall
            traffic flow in the city. It enables officers to make informed
            decisions and take timely actions to ensure a smoother and more
            efficient traffic system.
          </p>
        `,
        features: [
          {
            icon: "images/icons/patrol.png",
            heading: "Live Traffic Visualization",
            para: (
              <p>
                The platform uses real-time data for a comprehensive overview of
                citywide traffic conditions, aiding traffic officers in managing
                congestion and flow.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/danger (1).png",
            heading: "Alerts and Notifications",
            para: (
              <p>
                The platform sends alerts to nearby officers for prompt response
                to traffic congestion or incidents, facilitating quick measures
                to ease congestion.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/insight.png",
            heading: "Machine Learning-Based Recommendations",
            para: (
              <p>
                By leveraging machine learning algorithms, the platform
                generates intelligent recommendations to enhance traffic
                conditions.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/data.png",
            heading: "Data Analysis and Insights",
            para: (
              <p>
                The platform analyzes historical traffic data to identify
                patterns, assess the impact of events or road conditions, and
                inform data-driven decisions for traffic management and
                infrastructure planning.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/decision-making.png",
            heading: "Collaborative Decision Making",
            para: (
              <p>
                The platform promotes collaboration among traffic officers and
                stakeholders through a centralized platform for sharing
                information and coordinating efforts to address traffic
                challenges.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "e-Challan Module",
        splitName: ["e-Challan", "Module"],
        description: (
          <p>
            The E-challan is a technology-based solution to monitor traffic
            violations and has revolutionized traffic management in nashik
            state.
          </p>
        ),
        image: "",
        images: ["images/Live Traffic Application.jpg"],
        bgSiteImg: "images/3.3.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["e-Challan", "Module"],
        about_us_para: `
          <p>
           The e-challan system automatically detects the violation,instant generation of 
           e-challans, and electronic notifications to offenders reduce the time and effort 
           required for law enforcement officers to handle these cases.By utilising cameras 
           that are mounted on traffic lights, e-challans are given. Violators' email addresses 
           and mobile numbers will receive the challan.
          </p>
        `,
        features: [
          {
            icon: "images/icons/traffic-jam.png",
            heading: "Live Traffic Visualization",
            para: (
              <p>
                The platform utilizes real-time data to visualize the current
                traffic conditions in different areas of the city. This allows
                traffic officers to have a comprehensive overview of traffic
                congestion, bottlenecks, and other issues that may affect the
                flow of vehicles.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/danger (1).png",
            heading: "Alerts and Notifications",
            para: (
              <p>
                The platform sends alerts and notifications to nearby officers
                when traffic congestion or incidents occur. This ensures that
                officers can promptly respond to the situation, redirect traffic
                if necessary, and take appropriate measures to ease congestion.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/insight.png",
            heading: "Machine Learning-Based Recommendations",
            para: (
              <p>
                By leveraging machine learning algorithms, the platform
                generates intelligent recommendations to enhance traffic
                conditions.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/data-analysis (1).png",
            heading: "Data Analysis and Insights",
            para: (
              <p>
                The platform analyzes traffic data collected over time to
                generate valuable insights. These insights can be used to
                identify recurring traffic patterns, understand the impact of
                specific events or road conditions on traffic, and make
                data-driven decisions for traffic management and infrastructure
                planning.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/decision-making (1).png",
            heading: "Collaborative Decision Making",
            para: (
              <p>
                The platform facilitates collaboration among traffic officers
                and stakeholders by providing a centralized platform for sharing
                information, discussing strategies, and coordinating efforts to
                address traffic challenges.{" "}
              </p>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "VisitTrack: Visitor & Appointment Manager",
    mainHead: "VisitTrack: | Visitor & | Appointment Manager",
    logo_path: "images/logos/visit track logo.png",
    description: (
      <p>
        VisitTrack is a KIOSK system designed to track and maintain digital
        records of all visitors. It automates the generation of reports &
        analytics.
      </p>
    ),
    image: "images/VMS Dashboard.jpg",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [
      {
        icon: "images/icons/edit.png",
        heading: "Digital Visitor Record Keeping",
        para: (
          <p>
            The system digitally records visitors' personal details, purpose of
            visit, entry and exit times, and feedback in administrative offices.{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/settings (1).png",
        heading: "Automatic Report Generation",
        para: (
          <p>
            Our system automatically generates daily reports summarizing visitor
            activity, offering insights into visitor volumes, ratings, and other
            relevant statistics{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/review.png",
        heading: "Feedback Collection",
        para: (
          <p>
            The Visitor Management System allows visitors to share feedback
            through ratings, comments, or suggestions on their experience.{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/icons8-installing-updates-64 (1).png",
        heading: "Communication and Updates",
        para: (
          <p>
            Each department and officer receives regular updates via SMS and
            email, summarizing visitor data and relevant information to keep
            officials informed about activities and trends.{" "}
          </p>
        ),
      },
    ],
    about_us_image: "images/visit track.png",
    about_us_heading: ["VisitTrack:", "Visitor &", "Appointment", "Manager"],
    about_us_para: `
      <p>
        Our Visitor Management System is designed to track and maintain a
        digital record of all visitors entering various administrative offices,
        along with their feedback. This system automates the generation of
        comprehensive reports, including office-wise daily visitor summaries,
        ratings, charts, and statistics. These reports enable officials to
        monitor, maintain, and identify areas for record maintenance and
        improvement.
       
      </p>
    `,
    childrens: [],
  },
  {
    name: "TravelTrack: Travel e-Pass Manager",
    mainHead: "TravelTrack: | Travel | e-Pass | Manager",
    logo_path: "images/logos/travel e pass manager.png",
    description: (
      <p>
        TravelTrack provides a robust and sophisticated mechanism for obtaining
        digital & rapid vehicle mobility permissions from the authorities.{" "}
      </p>
    ),
    image: "images/16_epass system.jpg",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [
      {
        icon: "images/icons/internet filled.png",
        heading: "Citizens Portal",
        para: (
          <p>
            The Citizens Portal serves as an online platform where individuals
            can apply for travel e-passes. Users can submit their personal and
            vehicle details, along with the purpose of travel and any supporting
            documents required.{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/monitoring.png",
        heading: "Admin Dashboard",
        para: (
          <p>
            The Admin Dashboard is designed for authorities to review and
            approve e-pass applications. It provides a centralized system for
            monitoring and managing the approval process efficiently.{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/icons8-approval-40.png",
        heading: "Rapid Approval Process",
        para: (
          <p>
            The Travel e-Pass System streamlines the approval process, enabling
            swift evaluation and issuance of e-passes.{" "}
          </p>
        ),
      },
      {
        icon: "images/icons/icons8-safe-travel-64 (1).png",
        heading: "Safe Travel during the Pandemic",
        para: (
          <p>
            By providing a digital mechanism for obtaining e-passes, the system
            contributes to safe travel practices during the Covid-19 pandemic.{" "}
          </p>
        ),
      },
    ],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["TravelTrack:", "Travel", "e-Pass", "Manager"],
    about_us_para: `
      <p>
        The Travel e-Pass System has been developed to facilitate the smooth
        movement of essential and authorized services during the Covid-19
        lockdown period in Maharashtra State. It provides a simple and
        sophisticated mechanism for obtaining digital and rapid vehicle mobility
        permissions from the police and other authorities. The system comprises
        a Citizens Portal for applying and an Admin Dashboard for approvals and
        monitoring.
      </p>
    `,
    childrens: [],
  },
  {
    name: "InventoTrack: Inventory Manager",
    mainHead: "InventoTrack: | Inventory Manager",
    logo_path: "images/logos/inventory_manager.png",
    description: (
      <p>
        This platform is a solution to centrally manage inventories through
        app-based digitization and assigning QR/RFID to each item. Th
      </p>
    ),
    image: "images/inventoTrack.png",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Insight Analytics"],
    about_us_para: ``,
    childrens: [
      {
        name: "Logistics Management Module",
        splitName: ["Logistics", "Management", "Module"],
        description: (
          <p>
            This platform is a comprehensive solution for companies to centrally
            manage their logistics and inventories. It leverages app-based
            digitization and assigns QR stickers or RFID stickers to each item
            to facilitate efficient tracking and management.
          </p>
        ),
        image: "",
        images: ["images/Logistics Tracking.jpg"],
        bgSiteImg: "images/6.1.jpeg",
        about_us_image: "images/Invento track - logistics.png",
        about_us_heading: ["Logistics", "Management", "Module"],
        about_us_para: `
        <p>
        This platform is a comprehensive solution for companies to centrally manage their 
        logistics and inventories. It leverages app-based digitization and assigns QR 
        stickers or RFID stickers to each item to facilitate efficient tracking and management.
        Overall, this platform offers a comprehensive solution for managing logistics and 
        inventories through digitization, QR or RFID stickers, and online inventory management.
         It enhances tracking capabilities, reduces errors, and enables efficient inventory 
         management processes, ultimately leading to improved operational efficiency and security.
        </p>
      `,
        features: [
          {
            icon: "images/icons/icons8-digitalization-64 (1).png",
            heading: "App-Based Digitization",
            para: (
              <p>
                The platform enables companies to digitize crucial logistics and
                inventory information through a user-friendly mobile app. This
                digitization process captures and stores all necessary details,
                such as item descriptions, quantities, locations, and other
                relevant information.
              </p>
            ),
          },
          {
            icon: "images/icons/icons8-qr-code-96.png",
            heading: "QR and RFID Stickers",
            para: (
              <p>
                Each item in the inventory is assigned a unique QR sticker or
                RFID sticker. These stickers serve as identifiers for the items
                and allow for easy and accurate tracking throughout the supply
                chain. Companies can affix these stickers to their items for
                seamless tracking and management.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/icons8-inventory-management-64.png",
            heading: "Online Inventory Management",
            para: (
              <p>
                The platform maintains a centralized online database of
                inventory details. Users can access this database through the
                app, allowing for real-time visibility and control over the
                inventory. Information such as item locations, movement history,
                and status can be easily accessed and updated through the app.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/icons8-portrait-mode-scanning-96.png",
            heading: "Scanning and Updating",
            para: (
              <p>
                By scanning the QR code using the dedicated app or using an RFID
                scanner, users can quickly retrieve and update information about
                specific items. This functionality streamlines inventory
                management processes, reduces errors, and ensures accurate
                tracking of items.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/safety.png",
            heading: "Security and Loss Prevention",
            para: (
              <p>
                The platform aids in the identification, record-keeping, and
                tracking of all inventory items, helping companies prevent
                leaks, thefts, or misplacements. By maintaining accurate and
                up-to-date records, companies can mitigate risks and ensure the
                security of their inventory.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "Covid19 Medicine supply chain module",
        splitName: ["Covid19", "Medicine", "supply", "chain", "module"],
        description: (
          <p>
            The platform aims to introduce transparency in the supply chain of
            COVID-19 medicines to prevent black marketing and enable real-time
            monitoring by the FDA. It provides a comprehensive solution for
            tracking the availability and distribution of medicines,
            particularly medications like Remdesivir, during the COVID-19
            pandemic.
          </p>
        ),
        image: "",
        images: ["images/supply-chain.jpg"],
        bgSiteImg: "images/6.2.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Covid19", "Medicine", "supply", "chain", "module"],
        about_us_para: `
        <p>
        The platform aims to introduce transparency in the supply chain of COVID-19 medicines to prevent black marketing and enable real-time monitoring by the FDA. It provides a comprehensive solution for tracking the availability and distribution of medicines, particularly medications like Remdesivir, during the COVID-19 pandemic.
        By implementing this platform, the aim is to create a transparent and accountable supply chain for COVID-19 medicines, preventing black marketing, ensuring proper patient care, and enabling real-time monitoring and intervention by the FDA. It helps in maintaining an accurate record of medicine distribution, identifying fraudulent activities, and providing valuable insights to support efficient decision-making in the fight against the COVID-19 pandemic.
      </p>
        `,
        features: [
          {
            icon: "images/icons/icons8-transparency-64 (1).png",
            heading: "Transparency in Supply Chain",
            para: (
              <p>
                The platform ensures transparency by capturing and maintaining
                detailed records of the incoming and outgoing medicines. This
                includes information about stockists, distributors, retailers,
                pharmacies, and hospitals involved in the distribution process.
                By recording all transactions, the platform creates a
                transparent trail that can be monitored and audited by the FDA
                to identify any fraudulent activities.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/icons8-fraud-detection-64.png",
            heading: "Real-time Monitoring and Fraud Detection",
            para: (
              <p>
                The platform enables real-time monitoring of the availability
                and movement of COVID-19 medicines. This allows the FDA to have
                an up-to-date picture of the medicine supply chain, facilitating
                effective decision-making and proactive interventions to address
                shortages or prevent fraudulent practices. By identifying
                anomalies or suspicious activities, the platform helps in
                detecting and mitigating fraud within the supply chain.
              </p>
            ),
          },
          {
            icon: "images/icons/folder.png",
            heading: "Patient Data Recording",
            para: (
              <p>
                Pharmacies and hospitals using the platform are required to
                input patient details before selling the medicines. This step
                helps prevent the misuse of prescriptions and ensures that
                patients do not obtain the same medicine multiple times from
                different stores using a single or fraudulent prescription. By
                recording patient information, the platform adds an additional
                layer of accountability and prevents unauthorized access to
                critical medications.
              </p>
            ),
          },
          {
            icon: "images/icons/report.png",
            heading: "Compliance and Reporting",
            para: (
              <p>
                The platform assists in maintaining compliance with regulations
                and guidelines set by the FDA and other regulatory authorities.
                It provides a centralized system for generating reports on
                medicine transactions, patient data, and overall medicine
                availability. These reports can be used by the FDA for analysis,
                regulatory oversight, and decision-making purposes.
              </p>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "SmartTrack: Attendance Manager",
    mainHead: "SmartTrack: | Attendance Manager",
    logo_path: "images/logos/attendance_manager.png",
    description: (
      <p>
        SmartTrack is a two-way authentication system for attendance utilizing
        facial recognition and geo-fencing to monitor daily visits and leaves.
      </p>
    ),
    image: "images/11_AI based attendance.jpg",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [
      {
        icon: "images/icons/icons8-dashboard-50.png",
        heading: "Centralized Online Dashboard",
        para: (
          <p>
            The system offers an online dashboard that serves as a centralized
            hub for monitoring and managing various aspects of service personnel
            administration. It provides features for attendance tracking, duty
            tracking, and leave management, enabling efficient and streamlined
            operations.
          </p>
        ),
      },
      {
        icon: "images/icons/icons8-qr-code-96.png",
        heading: "Mobile App with QR Code Scanning",
        para: (
          <p>
            The mobile app associated with the system utilizes QR code scanning
            for check-in and check-out processes. Unique QR codes assigned to
            specific office venues facilitate location-based identification. The
            app also employs facial recognition software to enhance security and
            authenticity.
          </p>
        ),
      },
      {
        icon: "images/icons/location.png",
        heading: "Real-Time Location Tracking",
        para: (
          <p>
            By leveraging the mobile app's real-time GPS capabilities, the
            system provides accurate location tracking of service personnel.
            This feature enables administrators to monitor field personnel and
            ensure their presence at designated locations during duty hours.
          </p>
        ),
      },
      {
        icon: "images/icons/icons8-mobile-id-verification-96.png",
        heading: "Selfie Verification",
        para: (
          <p>
            The mobile app incorporates a selfie verification mechanism,
            allowing service personnel to authenticate their identity through
            captured selfies. This additional layer of security helps prevent
            unauthorized check-ins and promotes authenticity in the attendance
            tracking process.
          </p>
        ),
      },
    ],
    about_us_image: "images/smart track.png",
    about_us_heading: ["SmartTrack", "Attendance", "Manager"],
    about_us_para: `
      <p>
      SmartTrack is a two-way authentication system for attendance utilizing facial recognition and geo-fencing to monitor daily visits and leaves. 
      Our system empowers the administration with quick monitoring, attendance tracking, duty tracking, and leave management of all service personnel through a centralized online dashboard. The accompanying mobile app utilizes unique QR code scanning assigned to respective office venues, providing real-time location tracking and implementing a two-way security and authenticity mechanism through selfies.
      Our system combines convenience, security, and real-time monitoring to optimize the administration of service personnel. The centralized online dashboard and mobile app streamline attendance tracking, duty management, and leave processes, enhancing operational efficiency and accuracy.
      </p>
    `,
    childrens: [],
  },
  {
    name: "InvoiceTrack: Invoicing Manager",
    mainHead: "InvoiceTrack: | Invoicing Manager",
    logo_path: "images/logos/invoice tracker.png",
    description: (
      <p>
        This cloud-based application helps companies create, edit, manage, and
        share Quotations and Invoices quickly and efficiently. <br />
      </p>
    ),
    image: "images/Screenshot (217).png",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Insight Analytics"],
    about_us_para: ``,
    childrens: [
      {
        name: "Stone Invoicing Application",
        splitName: ["Stone", "Invoicing", "Application"],
        description: (
          <p>
            Application developed to generate, manage and share estimates,
            quotations, and invoices as per the requirements from customers in a
            very quick and efficient manner.
          </p>
        ),
        image: "",
        images: ["images/invoice.png"],
        bgSiteImg: "images/8.1.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Stone", "Invoicing", "Application"],
        about_us_para: `
          <p>
          Application developed to generate, manage and share estimates, quotations, and invoices as per the requirements from customers in a very quick and efficient manner. Whenever a user fills in the details of requirements of stone like, Stone type, category, quantity and the destination location, the system automatically estimates the material, freight cost and Taxes, and thus generates an editable quotation. It also has a stock management features, where the supplier can  monitor the current stock 
          </p>
        `,
        features: [
          {
            icon: "images/icons/data-analysis (1).png",
            heading: "Advanced GIS-based Data Analysis",
            para: (
              <p>
                Our solution employs advanced geographic information system
                (GIS) technology to analyze and visualize crime-related data.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "AeroFinTrack",
        splitName: ["AeroFinTrack"],
        description: (
          <p>
            Platform to enable multiple department to manage budgets, create and
            approve invoices and keep a track of payments and other financial
            parameters
          </p>
        ),
        image: "",
        images: ["images/aerofintrack.png"],
        bgSiteImg: "images/8.2.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: [""],
        about_us_para: `<p>Platform to enable multiple department to manage budgets, create and
        approve invoices and keep a track of payments and other financial
        parameters</p>`,
        features: [],
      },
    ],
  },
  {
    name: "HubSphere: Dynamic Websites & Platforms",
    mainHead: "HubSphere: | Dynamic Websites & Platforms",
    logo_path: "images/logos/hub sphere.png",
    description: (
      <p>
        This platform represents a cutting-edge, technology-driven, role-based
        (CMS) that empowers users to comprehensively manage their platforms
        through a sophisticated backend dashboard.
      </p>
    ),
    image: "images/ovochub.png",
    logo: "images/17_THIRD I vigilance.jpg",
    features: [],
    about_us_image: "images/sample img 2.png",
    about_us_heading: ["Third I:", "Insight Analytics"],
    about_us_para: ``,
    childrens: [
      {
        name: "OVOCHUB Platform",
        splitName: ["OVOCHUB", " Platform"],
        description: (
          <p>
            The Our Voices Our Choices (OVOC) Project Hub website is built on
            our HubSphere Platform. It is a dynamic and responsive website that
            is mobile-friendly, user-friendly, and equipped with automatic
            language translation for English, Bengali, Nepali, and Thai.
          </p>
        ),
        image: "",
        images: ["images/ovochub.png"],
        bgSiteImg: "images/9.1.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["OVOCHUB", " Platform"],
        about_us_para: `
          <p>
          The website is compatible with all common browsers and has exclusive customization options for the admin and content creators.
          The website incorporates Google analytics and few third-party analytics services. 
          The website also have an interactive chat board with automatic translation for English, Bengali, Nepali, and Thai.
          </p>
        `,
        features: [
          {
            icon: "images/icons/user-friendly.png",
            heading: "user friendly interface",
            para: (
              <p>
                This feature enables seamless communication and ensuring a
                positive and efficient user experience.
              </p>
            ),
          },
          {
            icon: "images/icons/translation (1).png",
            heading: "Automatic translation",
            para: (
              <p>
                This feature automatically translates text into Thai, English,
                Bengali, and Nepali, facilitating easy communication across
                these languages.
              </p>
            ),
          },
          {
            icon: "images/icons/mobile-friendly.png",
            heading: "Mobile Friendly",
            para: (
              <p>
                This feature facilitates site that is modified to fit user's
                mobile device(Tablet, iPad, or smartphone) and is easy to
                navigate without user needing to zoom in or adjust their
                settings manually.
              </p>
            ),
          },
        ],
      },
      {
        name: "Content Management System",
        splitName: ["Content", "Management", "System"],
        description: (
          <p>
            We have developed a dynamic website with knowledge Bank section
            (learning management System) where the customer can create, edit and
            update, Blogs, Articles, laws, Research, learning documents, videos
            etc. from the backend Dashboard
          </p>
        ),
        image: "",
        images: ["images/CMS_Homepage.png"],
        bgSiteImg: "images/9.2.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["Content", "Management", "System"],
        about_us_para: `<p>
        We have developed a dynamic website with knowledge Bank section
            (learning management System) where the customer can create, edit and
            update, Blogs, Articles, laws, Research, learning documents, videos
            etc. from the backend Dashboard</p>`,
        features: [
          {
            icon: "images/icons/data-analysis (1).png",
            heading: "Advanced GIS-based Data Analysis",
            para: (
              <p>
                Our solution employs advanced geographic information system
                (GIS) technology to analyze and visualize crime-related data.{" "}
              </p>
            ),
          },
          {
            icon: "images/icons/map.png",
            heading: "Crime Hotspots and Criminal Activity Visualization",
            para: (
              <p>
                Through our platform, law enforcement agencies can easily
                identify crime hotspots and visualize criminal activity patterns
                on a map.{" "}
              </p>
            ),
          },
        ],
      },
      {
        name: "V-Change: A UN Volunteering Application",
        splitName: ["V-Change", "A UN Volunteering Application"],
        description: (
          <p>
            The V-Change Platform is a dedicated platform developed to empower
            volunteers and enhance their engagement in the United Nations
            Volunteers (UNV) programme.
          </p>
        ),
        image: "",
        images: ["images/17_THIRD I vigilance.jpg"],
        bgSiteImg: "images/9.3.jpeg",
        about_us_image: "images/sample img 2.png",
        about_us_heading: ["V-Change", "A UN Volunteering Application"],
        about_us_para: `<p>
        The V-Change Platform is a dedicated platform developed to empower volunteers and enhance their engagement in the United Nations Volunteers (UNV) programme. It provides a range of features and functionalities to facilitate volunteer sign-ups, task applications, and networking opportunities. The platform aims to promote volunteerism worldwide and contribute to peace and development efforts.
        The V-Change Platform aims to create a seamless and engaging experience for volunteers participating in the UNV programme. By leveraging technology, the platform encourages volunteer participation, provides recognition for their efforts, and facilitates knowledge sharing and networking within the volunteer community.
        </p>`,
        features: [
          {
            icon: "images/icons/online-registration.png",
            heading: "Volunteer Sign-up and Task Applications",
            para: (
              <p>
                The platform allows individuals to easily sign up as volunteers
                and create their profiles. Volunteers can browse through a wide
                range of location-based, skill-based, and cause-based
                opportunities and apply for tasks that align with their
                interests and expert ise.
              </p>
            ),
          },
          {
            icon: "images/icons/investigation.png",
            heading: "Opportunity Discovery",
            para: (
              <p>
                The platform provides a comprehensive database of volunteer
                opportunities, categorized by location, skills required, and
                causes supported. Volunteers can explore these opportunities,
                search for specific types of tasks, and find meaningful ways to
                contribute to various peace and development initiatives.
              </p>
            ),
          },
          {
            icon: "images/icons/best-customer-experience.png",
            heading: "Badges, Certificates, and Social Sharing",
            para: (
              <p>
                As volunteers engage in tasks and contribute their time and
                skills, they can earn badges and certificates to recognize their
                achievements and contributions. These achievements can be shared
                on social media channels, allowing volunteers to showcase their
                commitment to volunteerism and inspire others to get involved.
              </p>
            ),
          },
          {
            icon: "images/icons/info (2).png",
            heading: "Task Status Updates and Multimedia Sharing",
            para: (
              <p>
                Volunteers can share updates on their task progress, including
                photos and videos, to provide a visual representation of their
                volunteer experiences. This feature allows volunteers to
                communicate their impact and share inspiring stories with the
                wider community.
              </p>
            ),
          },
          {
            icon: "images/icons/learning (1).png",
            heading: "Volunteer Community and Learning Zone",
            para: (
              <p>
                The platform fosters a sense of community among volunteers,
                providing opportunities for interaction and collaboration.
                Volunteers can connect with fellow volunteers, exchange ideas,
                share experiences, and learn from each other in the dedicated
                learning zone. This zone may include resources, training
                materials, and educational content related to volunteerism,
                peace, and development.
              </p>
            ),
          },
        ],
      },
    ],
  },
];
