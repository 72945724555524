import React, { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import Button from "react-bootstrap/Button";
import Navbar from "./Navbar";
import "./ContactFormWithMap.css";
import Toast from "react-bootstrap/Toast";
import emailjs from "@emailjs/browser";
import { Tooltip } from "react-bootstrap";

const ContactFormWithMap = () => {
  const [showToast, setShowToast] = useState(false);
  const center = [25.1375404, 75.8482055];
  const positions = [
    { location: [25.1375404, 75.8482055], title: "Adivid Technologies, Kota" },
    {
      location: [20.001762397140443, 73.74945315333356],
      title: "Adivid Technologies, Nashik",
    },
  ];
  const customIcon = new Icon({
    iconUrl: require("../icons/placeholder.png"),
    iconSize: [38, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      e.target.user_name.value === "" ||
      e.target.user_email.value === "" ||
      e.target.message.value === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setShowToast(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="contact_container" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-2">
              <div className="col-sm-12 text-center">
                <h6 style={{ fontSize: "2rem" }}>Contact Us</h6>
              </div>
            </div>
            <div className="col-md-6">
              <MapContainer
                center={center}
                zoom={5}
                style={{ height: "400px", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {positions.map((curAddress, index) => (
                  <Marker
                    position={curAddress.location}
                    icon={customIcon}
                    key={index}
                  >
                    <Popup>{curAddress.title}</Popup>
                    <Tooltip>{curAddress.title}</Tooltip>
                  </Marker>
                ))}
              </MapContainer>
            </div>

            <div className="col-md-6 form_container mt-4">
              <form ref={form} onSubmit={sendEmail}>
                <div class="form-group">
                  <label
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      fontWeight: "600",
                    }}
                    for="exampleFormControlInput1"
                  >
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your name"
                    name="user_name"
                  />
                </div>
                <div class="form-group">
                  <label
                    className="mt-2"
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      fontWeight: "600",
                    }}
                    for="exampleFormControlInput2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput2"
                    placeholder="Enter your Email address"
                    name="user_email"
                  />
                </div>
                <div class="form-group">
                  <label
                    className="mt-2"
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      fontWeight: "600",
                    }}
                    for="exampleFormControlTextarea3"
                  >
                    Message
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea3"
                    placeholder="Enter your message"
                    rows="3"
                    name="message"
                  ></textarea>
                </div>
                <Button
                  className="mt-3"
                  variant="primary"
                  type="submit"
                  style={{
                    borderRadius: "0.8rem",
                  }}
                >
                  Submit
                </Button>
              </form>
              <Toast
                className="mt-2"
                show={showToast}
                onClose={() => setShowToast(false)}
              >
                <Toast.Header>
                  <strong className="me-auto">Email Sent!</strong>
                </Toast.Header>
                <Toast.Body>Your email has been successfully sent.</Toast.Body>
              </Toast>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactFormWithMap;
