import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer id="section6" className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Contact</h4>
            <ul>
              <li>
                <i
                  className="fa fa-envelope fa-2x"
                  style={{
                    color: "white",
                  }}
                ></i>
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                  info@adivid.com
                </p>
              </li>
              <li>
                <i
                  className="fa fa-phone fa-2x"
                  style={{
                    color: "white",
                  }}
                ></i>
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                  +91 97992 35966
                </p>
              </li>
              <li>
                <i
                  className="fa fa-map-marker fa-2x"
                  style={{
                    color: "white",
                  }}
                ></i>
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                  C 8/1, NICE Area, Satpur MIDC, Nashik, India 422007
                </p>
              </li>
              <li>
                <i
                  className="fa fa-map-marker fa-2x"
                  style={{
                    color: "white",
                  }}
                ></i>
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                  267, Rajeev Gandhi Nagar Kota, Rajasthan, India - 324005
                </p>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <a
                href="https://www.facebook.com/adividtechnologies/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/AdividT"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/adivid/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.instagram.com/adividtechnologies/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="copyright">
            <p>
              <a href="https://adivid.com/privacy-policy/">Privacy Policy</a>
              <span style={{ margin: "0px 10px" }}> |</span>
              <a href="https://adivid.com">
                Copyright © 2023 Adivid Technologies Pvt Ltd
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
