import "./Awards.css";

const Awards = () => {
  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <div data-aos="fade-up" data-aos-anchor-placement="top-center">
          <div id="awards_container">
            <div className="awards_container pb-4">
              <div className="container">
                {/* <div className="titlepage">
                  <h2>Awards and Achievements</h2>
                </div> */}
                <div className="col-sm-12 text-center">
                  <h6>Awards and Achievements</h6>
                </div>

                <div className="grid-wrapper">
                  <div className="awards_item">
                    <img src="images/DelhiPolice.jpg" loading="lazy" />
                    <div className="awards_item-hover">
                      <p>
                        Winner at Delhi Police Open Innovation Challenge 2021
                      </p>
                    </div>
                  </div>

                  <div className="awards_item">
                    <img src="images/CSI SIG 2 Award.jpg" loading="lazy" />
                    <div className="awards_item-hover">
                      <p>
                        Awarded CSI SIG e-Governance Award 2019-20 (for Travel
                        e-pass system in State level Initiatives)
                      </p>
                    </div>
                  </div>
                  <div className="awards_item">
                    <img src="images/CSI Award 2.jpg" loading="lazy" />
                    <div className="awards_item-hover">
                      <p>
                        Awarded CSI SIG e-Governance Award 2018-19 (for Third I
                        Platform in 2018-19 in local gov. initiative)
                      </p>
                    </div>
                  </div>
                  <div className="awards_item">
                    <img src="images/FICCI Certi.jpg" loading="lazy" />
                    <div className="awards_item-hover">
                      <p>
                        Awarded FICCI Best Smart Policing Initiative 2017-18
                        Award (Category: Smart Police Station)
                      </p>
                    </div>
                  </div>
                  <div className="awards_item">
                    <img
                      src="images/Social Alpha UL Winners.png"
                      loading="lazy"
                    />
                    <div className="awards_item-hover">
                      <p>
                        Winner at Social Alpha Urban Liveability Contest 2019-20
                      </p>
                    </div>
                  </div>
                  <div className="awards_item">
                    <img
                      src="images/Felicitation by CTO & VP.jpg"
                      loading="lazy"
                    />
                    <div className="awards_item-hover">
                      <p>
                        Felicitated by TCS CTO as one of the best social
                        initiatives at Digital Impact Square (TCS Foundation)
                      </p>
                    </div>
                  </div>
                  <div className="awards_item">
                    <img src="images/DSC_5130.JPG" loading="lazy" />
                    <div className="awards_item-hover">
                      <p>
                        Felicitated by Honourable Governor C Vidyasagar Rao,
                        Addl. DGP Admin Dr Pradnya Saravade, Maharashtra. ADG
                        Shri K. Venkatesham
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
