import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Navbar.css";
import NavDropDown from "./NavDropDown";

function FeaturedPageNavbar({ childLen, item, logo_path }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbarBackground, setNavbarBackground] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const changeNavbarColor = () => {
    const scrollPosition = window.scrollY;
    const threshold = 700; // Adjust this value based on when you want the background to change

    if (scrollPosition > threshold) {
      setNavbarBackground(true); // Set the background color when scrolling past the threshold
    } else {
      setNavbarBackground(false); // Set the default background color
    }
  };
  const handleScrollToElement = (id) => {
    const testNode = document.getElementById(id);

    if (testNode) {
      const offset = 80;
      const targetPosition =
        testNode.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // Adds smooth scrolling animation
      });
    }
  };

  useEffect(() => {
    showButton();
    changeNavbarColor();
  }, []);

  window.addEventListener("resize", showButton);
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <nav
      className="navbar"
      style={navbarBackground ? { backgroundColor: "white" } : {}}
    >
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo_path} />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i
            className={click ? "fas fa-times" : "fas fa-bars"}
            style={!navbarBackground ? { color: "white" } : {}}
          />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={() => {
                closeMobileMenu();
              }}
            >
              Home
            </Link>
          </li>
          {childLen === 0 && (
            <li className="nav-item">
              <a
                href="#about-us"
                className="nav-links"
                style={
                  navbarBackground ? { color: "black" } : { color: "white" }
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToElement("about-us");
                  closeMobileMenu();
                }}
              >
                About
              </a>
            </li>
          )}
          {childLen > 0 && (
            <li className="nav-item">
              <a
                href="#projects"
                className="nav-links"
                style={
                  navbarBackground ? { color: "black" } : { color: "white" }
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToElement("projects");
                  closeMobileMenu();
                }}
              >
                Projects
              </a>
            </li>
          )}
          <li className="nav-item">
            <NavDropDown navbarBackground={navbarBackground} />
          </li>
          <li className="nav-item">
            <a
              href="#features"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("features_section");
                closeMobileMenu();
              }}
            >
              Features
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default FeaturedPageNavbar;
