import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import NavDropDown from "./NavDropDown";
import "./Navbar.css";

function Navbar({ homeRef }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbarBackground, setNavbarBackground] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const changeNavbarColor = () => {
    const scrollPosition = window.scrollY;
    const threshold = 800; // Adjust this value based on when you want the background to change

    if (scrollPosition > threshold) {
      setNavbarBackground(true); // Set the background color when scrolling past the threshold
    } else {
      setNavbarBackground(false); // Set the default background color
    }
  };

  useEffect(() => {
    showButton();
    changeNavbarColor();
  }, []);

  const handleScrollToElement = (id) => {
    const testNode = document.getElementById(id);

    if (testNode) {
      const offset = 95;
      const targetPosition =
        testNode.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // Adds smooth scrolling animation
      });
    }
  };

  window.addEventListener("resize", showButton);
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <nav
      className="navbar"
      style={navbarBackground ? { backgroundColor: "white" } : {}}
    >
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img
            src={
              navbarBackground
                ? "images/adivid_logo.png"
                : "images/adividwhite - Copy.png"
            }
            style={{ width: "150px", height: "auto" }}
          />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i
            className={click ? "fas fa-times" : "fas fa-bars"}
            style={!navbarBackground ? { color: "white" } : {}}
          />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <a
              href="#hero"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("hero");
                closeMobileMenu();
              }}
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#featured"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("featured");
                closeMobileMenu();
              }}
            >
              Featured
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              href="#products"
              className="nav-links"
              onClick={() => {
                scrollToHome();
                closeMobileMenu();
              }}
            >
              Products
            </a>
          </li> */}
          <li className="nav-item">
            <NavDropDown navbarBackground={navbarBackground} />
          </li>
          <li className="nav-item">
            <a
              href="#awards_container"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("awards_container");
                closeMobileMenu();
              }}
            >
              Awards
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#partners_container"
              className="nav-links"
              style={navbarBackground ? { color: "black" } : { color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("partners_container");
                closeMobileMenu();
              }}
            >
              Partners
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              href="#contact"
              className="nav-links"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("contact");
                closeMobileMenu();
              }}
            >
              Contact
            </a>
          </li> */}
          {!button && (
            <li>
              <a
                href="#contact"
                className="nav-links"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToElement("contact");
                  closeMobileMenu();
                }}
              >
                Request Demo
              </a>
            </li>
          )}
        </ul>
        <div className="nav-btn">
          {button && (
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToElement("contact");
                closeMobileMenu();
              }}
            >
              <button
                className="demo-btn"
                style={
                  navbarBackground
                    ? { color: "black", border: "2px solid black" }
                    : { color: "white", border: "2px solid white" }
                }
              >
                Request Demo
              </button>
            </a>
          )}
        </div>
      </div>
    </nav>
  );
}
{
  /* <div className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src="images/adivid-logo.png" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul>
          <li className="nav-items">
            <Link>Home</Link>
          </li>
          <li className="nav-items">Products</li>
          <li className="nav-items">Contact</li>
        </ul>
      </div>
    </div> */
}

export default Navbar;
